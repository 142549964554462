<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
html,
body,
#app {
  @apply w-full h-full;
}

::-webkit-scrollbar {
  @apply w-1;
}
::-webkit-scrollbar-track-piece {
  @apply bg-transparent;
}
::-webkit-scrollbar-thumb {
  @apply bg-black bg-opacity-10 rounded;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
